import React from "react";
import Swiper from "swiper";
import Visual from "./components/Visual";

class Work20 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = "#" + el.getAttribute("id");
        if (swiperId === "#appbar-swiper") {
          return;
        }
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };

  render() {
    return (
      <div className="page">
        <div className="sec sec1">
          <Visual id="20" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                NAVER
                <br />
                Partner Square
                <br />
                Gwangju
              </h3>
              <ul>
                <li>#Brand Experience Design </li>
                <li>#Space Design </li>
                <li>#UX/UI Design </li>
                <li>#Contents Design </li>
                <li>#Contents Development </li>
                <li>#Installation</li>
              </ul>
            </div>
            <div>
              <h4>“호남지역 산지의 특별함을 담다” 상설전시 구축</h4>
              <p>
                네이버는 호남지역의 사업자와 창작자의 창업과 성장을 지원하기
                위해 전라도 광주에 파트너스퀘어를 오픈하였습니다. 산과 평야
                그리고 바다가 어우러진 맛의 고장 호남, 음식과 식자재로 특화된
                호남의 특색을 살려 ‘네이버 푸드윈도’에서 활약하는 생산자의
                이야기를 생생하게 들려주어 네이버 서비스의 플랫폼으로서 역할과
                공간의 의미를 전달할 수 있는 전시를 기획하였습니다. 로비 1층에
                마련된 전시공간은 시설을 이용하는 창작자 뿐만 아니라 다양한
                방문자들이 자유롭게 체험할 수 있는 오픈 공간으로 운영되며
                생산자의 진심의 이야기와 서비스의 역할에 대한 관심을 이끌어내는
                공간으로 자리하고 있습니다.
                <br />
                <br />
              </p>
              <p className="font-semibold">2018. 09</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/348734580"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
        </div>
        <div className="sec" style={{ backgroundColor: "#3fb40c" }}>
          <img className="m-img" src="/image/works/20/sec3_img.jpg" />
        </div>
        <div className="sec pb-10">
          <div className="sec-title-wrap mt-12">
            <h3 className="alogo">SPACE DESIGN</h3>
            <p>
              안과 밖, 층간 경계가 최소화된 개방형 구조로 설계된 네이버
              파트너스퀘어 광주의 공간 특성을 반영하여 부담없이 어울릴 수 있는
              열린 공간, 사방이 들여다보이는 투명한 전시공간을 연출하였습니다.
              푸드윈도를 찬찬히 거닐면 소비자들이 선정한 우수한 상품들을 산지의
              자연 풍경과 생산과정을 담은 테마 영상으로 만나고 체험할 수
              있습니다. 푸드 윈도는 크게 3개 공간으로 구분됩니다. 생산품 소개
              영역(가공식품, 지역명물, 전통주 / 채소, 농산, 과일 / 수산물 /
              축산물)을 지나면 오디오 메시지 영역, 비디오 스크린 영역으로 체험이
              이어집니다.
            </p>
          </div>
          <img
            className="m-img"
            src="/image/works/20/sec4_img.jpg"
            style={{ marginTop: "7.8%" }}
          />
          <img
            className="m-img"
            src="/image/works/20/sec4_img2.jpg"
            style={{ marginTop: "3%" }}
          />
        </div>
        <div className="sec swiper-sec py-5">
          <div className="swiper-container detail-swiper" id="swiper1">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_04.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_05.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_06.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_07.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_08.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_09.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_10.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_11.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_12.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec5_slide_13.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
        </div>
        <div className="sec pb-10">
          <div className="sec-title-wrap mt-12">
            <h3 className="alogo">BX MEDIA DESIGN</h3>
            <p>
              생산자의 정성과 노력을 실감할 수 있는 전시체험 미디어를
              설계하였습니다. 생산지의 전경을 푸드윈도(55인치 스크린)에 담아 각
              생산품의 테마 영상을 선보이고 생산자의 모습이 담긴 소형 터치형
              액자를 관람자의 손이 닿는 위치에 전시하였습니다. 액자 가까이 손이
              닿으면 해당 생산자가 들려주는 생산품 이야기가 눈 앞에 펼쳐집니다.
            </p>
          </div>
          <img className="m-img mt-10" src="/image/works/20/sec6_img1.jpg" />
          <img className="m-img mt-5" src="/image/works/20/sec6_img2.jpg" />
          <img className="m-img mt-5" src="/image/works/20/sec6_img3.jpg" />
        </div>
        <div className="sec swiper-sec py-5">
          <div className="detail-swiper swiper-container" id="swiper2">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/20/sec7_slide_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec7_slide_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec7_slide_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/20/sec7_slide_04.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
        </div>
        <div className="sec dark-bg pb-12">
          <div className="sec-title-wrap mt-12">
            <h3>CONTENTS DESIGN</h3>
            <p>
              산지의 자연 환경과 생산자의 육성을 생생하게 전달하고자 주로
              이미지로 콘텐츠를 구성하고 산지에서 식탁에 이르기까지 여정을 느낄
              수 있도록 타임라인 형식으로 스토리를 연출하였습니다.
            </p>
            <img className="m-img mt-10" src="/image/works/20/sec8_01.jpg" />
            <img className="m-img mt-10" src="/image/works/20/sec8_02.gif" />
            <div className="desktop-column mt-12">
              <img src="/image/works/20/sec8_03.jpg" />
              <img src="/image/works/20/sec8_04.jpg" />
            </div>
          </div>
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Choi Dong Hoon, Jung Su Han, Kim Jong Soon, Kim Do Hyung, Yoon Se
              Hee
              <br />
              Space Design : DesignM4
            </p>
          </div>
        </div>
        {/* <img className="prototype" src="/prototype/w20.png" /> */}
      </div>
    );
  }
}

export default Work20;
