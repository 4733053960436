import React from "react";
import { withRouter } from "react-router-dom";
import WorksDesktop from "../components/WorksDesktop";
import WorksMobile from "../components/WorksMobile";

class Works extends React.Component {
  state = {
    isDesktop: true
  };
  
  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  };

  onResize = () => {
    const isDesktop = document.body.offsetWidth > 578;
    if (this.state.isDesktop !== isDesktop) {
      this.setState({ isDesktop: isDesktop });
    }
  };

  render() {
    return this.state.isDesktop ? <WorksDesktop /> : <WorksMobile />;
  }
}

export default withRouter(Works);
