import React from "react";
import Visual from "./components/Visual";

class Work02 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page">
        {/* <img className="prototype" src="/prototype/w02.png" /> */}
        <div className="sec sec1">
          <Visual id="02" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                Google
                <br />
                Impact Challenge
                <br />
                Korea Campaign
              </h3>
              <ul>
                <li>#Campaign Planning </li>
                <li>#Brand Identity Design </li>
                <li>#UX/UI Design </li>
                <li>#Viral Move Production </li>
                <li>#Media Installation </li>
                <li>#Event Operation</li>
              </ul>
            </div>
            <div>
              <h4>Google 글로벌 캠페인 기획/제작/운영, 브랜드 필름 제작</h4>
              <p>
                구글 임팩트 챌린지는 세상을 밝히는 아이디어를 지원하는 구글의
                글로벌 사회 혁신 프로젝트 공모전으로 2016년 9월 한국에서
                개최되었습니다. 구글의 프로젝트를 널리 알리고 우승 프로젝트 선정
                투표에 시민들이 직접 참여할 수 있는 캠페인을
                기획/제작하였습니다.
                <br />
                <br />
                <span className="font-semibold">
                  “세상을 밝히는 아이디어에 불을 켜주세요.”
                </span>
                <br />
                사회 공헌 활동에 무관심하고 무엇보다 참여에 소극적인 대한민국,
                우리는 어떻게 하면 사람들의 참여를 독려할 수 있을지 고민하였고
                대중이 자신의 투표로 아이디어가 세상 밖으로 나오게 된다는 사실
                그리고 나아가 세상을 밝힐 수 있다는 희망을 직접 보고 경험할 수
                있는 기회를 제공하고자 하였습니다.
                <br />
                사전 홍보영상과 온라인 광고를 제작하여 10개의 비영리단체
                결승진출팀의 아이디어와 캠페인에 대한 관심을 이끌고, 보다
                성공적인 프로젝트 목표를 이루기 위해 온라인 투표를 오프라인
                투표까지 확장하며 캠페인의 전방위적 커뮤니케이션 전략을
                수립하였습니다.
              </p>
              <p className="font-semibold">2016. 09</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/346088488"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
        </div>
        <div className="sec" style={{ backgroundColor: "#4385f5" }}>
          <img className="l-img" src="/image/works/02/img01.jpg" />
        </div>
        <img className="bg" src="/image/works/02/bg02.jpg" />
        <div className="sec">
          <div className="sec-title-wrap mt-10">
            <h3>Off-Line Voting Media & Interactive Message Wall</h3>
            <p>
              디지털 투표 키오스크와 응원 메시지를 작성하면 불이 밝혀지는
              인터렉티브 메시지 월을 활용하여 캠페인 참여 분위기를 고조시고
              기억에 남는 투표 이벤트를 제공하고자 했습니다. <br />
              <br />
              오프라인 투표에 참여하면 인증 사진과 메시지 카드가 함께
              출력됩니다. 특수 처리된 메시지 카드의 전구 부분을 떼어내어 응원
              메시지 작성에 사용하고 인증 사진이 인쇄된 나머지 부분은 재미있는
              인증 사진을 연출하여 본인의 소셜 채널에 포스팅 할 수 있도록
              제작하여 캠페인 바이럴을 유도 하였습니다.
            </p>
          </div>
          <div>
            <img className="m-img mt-10" src="/image/works/02/img02.jpg" />
            <img className="m-img mt-5" src="/image/works/02/img03.jpg" />
            <img className="m-img mt-5" src="/image/works/02/img04.jpg" />
            <img className="m-img mt-5" src="/image/works/02/img05.jpg" />
            <img className="m-img mt-5" src="/image/works/02/img06.jpg" />
          </div>
          <h4>Kiosk Contents Design</h4>
          <div>
            <img
              className="m-img"
              src="/image/works/02/img07.jpg"
              style={{ marginTop: 28 }}
            />
            <img className="m-img mt-5" src="/image/works/02/img08.jpg" />
            <div className="video-container mt-5">
              <iframe
                src="https://player.vimeo.com/video/346088972"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              />
            </div>
          </div>
          <h4>Message Card Design</h4>
          <div>
            <img
              className="m-img"
              src="/image/works/02/img09.jpg"
              style={{ marginTop: 28 }}
            />
            <img className="m-img mt-5" src="/image/works/02/img10.jpg" />
          </div>
        </div>
        <div className="sec">
          <div className="sec-title-wrap mt-10">
            <h3>HERO VIDEO</h3>
            <p>
              사회 혁신의 소중한 씨앗이 될 아이디어의 고유한 가치와 내용을
              임팩트 있게 전달하고자 각각의 아이디어를 독립형 체험 부스에
              전시하였습니다. 전체 구성은 개별 부스가 유기적으로 연결된
              ‘VOTE’형태로 연출하여 캠페인 고지와 투표를 독려하는 메시지를 공간
              전체에서 경험할 수 있도록 동선을 설계하였습니다.
            </p>
            <div className="video-container mt-10">
              <iframe
                src="https://player.vimeo.com/video/346090599"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              />
            </div>
          </div>
          <img className="m-img mt-10" src="/image/works/02/img11.jpg" />
          <img className="m-img mt-5" src="/image/works/02/img12.jpg" />
        </div>
        <div className="sec mt-10">
          <div className="sec-title-wrap">
            <h3 className="alogo">CAMPAIGN IDENTITY</h3>
            <p>
              추상적인 컨셉인 ‘idea’를 직관적으로 형상화한 전구 모티브와 1차
              선정된 10가지 아이디어를 아이콘 형태의 오브제로 제작하여 캠페인의
              핵심 메시지를 쉽게 인지할 수 있도록 하였습니다.
            </p>
          </div>
          <img className="m-img my-10" src="/image/works/02/img13.jpg" />
        </div>
        <div className="sec py-10" style={{ backgroundColor: "#ececec" }}>
          <img className="m-img" src="/image/works/02/img14.jpg" />
          <img className="m-img mt-5" src="/image/works/02/img15.jpg" />
          <img className="m-img mt-5" src="/image/works/02/img16.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Choi Dong Hoon, Kim Jong Soon, Jung Su Han, Im Cho Min, Kim Do
              Hyung, Byeon Tae Jun
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work02;
