import React from "react";
import { observer } from "mobx-react";
import windowSize from "../windowSize";

@observer
class AboutWhiteBackgroundShape extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const maxInnerWidth = 900;
    const windowWidth = windowSize.width;
    let innerWidth;

    if (windowWidth < 600) {
      innerWidth = windowWidth + 400;
    } else {
      innerWidth = windowWidth * 0.75;
      innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;
    }

    const boxSize = innerWidth / 2;
    const xCenter = windowWidth / 2;
    const xStart = xCenter - innerWidth / 2;

    let yStart = 420;
    const marginTop = (windowWidth - xStart) / 2 - boxSize;
    if (marginTop > 200) {
      yStart = 420 - marginTop + 200;
    }

    if (windowWidth < 600) {
      yStart += 370;
    }

    const pt1 = { x: windowWidth, y: yStart };
    const pt2 = { x: xStart, y: yStart + (windowWidth - xStart) / 2 };
    const pt3 = { x: pt2.x + boxSize / 2, y: pt2.y - boxSize };
    const pt4 = {
      x: pt3.x + windowSize.height * 2,
      y: pt3.y + windowSize.height * 4
    };
    const pt5 = { x: pt2.x + boxSize * 0.8, y: pt3.y + boxSize * 0.6 };

    return (
      <svg width="100%" height="100%" className="background-shape2">
        <polyline
          points={`${pt1.x} ${pt1.y}, ${pt5.x} ${pt5.y}, ${pt4.x} ${pt4.y}`}
          iterlimit="10"
          style={{
            fill: "#fafafb",
            stroke: "rgba(0, 0, 0, 0.2)",
            strokeWidth: 1
          }}
        />

        <path
          d={`M ${pt5.x} ${pt5.y} l ${-pt5.x + pt2.x} ${-pt5.y + pt2.y} Z`}
          stroke="rgba(0, 0, 0, 0.2)"
        />
        <path
          d={`M ${pt5.x} ${pt5.y} l ${-pt5.x + pt3.x} ${-pt5.y + pt3.y}`}
          stroke="rgba(0, 0, 0, 0.2)"
        />
        <path
          d={`M ${pt2.x} ${pt2.y} l ${-pt2.x + pt3.x} ${-pt2.y + pt3.y}`}
          stroke="rgba(0, 0, 0, 0.2)"
          strokeDasharray="2,2"
        />
      </svg>
    );
  }
}

export default AboutWhiteBackgroundShape;
