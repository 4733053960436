import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Footer from "../components/Footer";
import Work01 from "./works/Work01";
import Work02 from "./works/Work02";
import Work06 from "./works/Work06";
import Work08 from "./works/Work08";
import Work09 from "./works/Work09";
import Work10 from "./works/Work10";
import Work12 from "./works/Work12";
import Work17 from "./works/Work17";
import Work18 from "./works/Work18";
import Work20 from "./works/Work20";
import Work21 from "./works/Work21";
import axios from "axios";
import constants from "../constants";

class WorkDetail extends React.Component {
  state = {
    projects: [],
    nextProject: {}
  };

  getNextProject() {
    const projects = this.state.projects;
    if (projects.length == 0) {
      return {};
    }

    let nextProject = projects[0];
    const currentId = this.props.match.params.id;
    for (let i = 0; i < projects.length - 1; i++) {
      const item = projects[i];

      const isCurrent = parseInt(item.id) === parseInt(currentId);
      if (isCurrent) {
        for (let j = i + 1; j < projects.length; j++) {
          const project = projects[j];
          if (project.is_updated) {
            nextProject = project;
            break;
          }
        }
      }
    }

    return nextProject;
  }

  componentDidMount = async () => {
    const workResponse = await axios.get(constants.dataBaseUrl + "/works.json");
    const { data } = workResponse;

    this.setState({ projects: data });
  };

  render() {
    const nextProject = this.getNextProject();

    return (
      <div className="scroll-container">
        <Switch>
          <Route path="/works/1" component={Work01} />
          <Route path="/works/2" component={Work02} />
          <Route path="/works/6" component={Work06} />
          <Route path="/works/8" component={Work08} />
          <Route path="/works/9" component={Work09} />
          <Route path="/works/10" component={Work10} />
          <Route path="/works/12" component={Work12} />
          <Route path="/works/17" component={Work17} />
          <Route path="/works/18" component={Work18} />
          <Route path="/works/20" component={Work20} />
          <Route path="/works/21" component={Work21} />
          <Redirect from="/works/:id" exact to="/works" />
        </Switch>
        <div
          style={{ background: "white", zIndex: "10", position: "relative" }}
        >
          <div
            className="next-proejct-container"
            style={{ display: nextProject.id ? "block" : "none" }}
          >
            <p className="desc font-semibold">Next Project</p>
            <h3
              className="title alogo"
              dangerouslySetInnerHTML={{
                __html: nextProject.title
              }}
              onClick={() => {
                this.props.history.replace("/works/" + nextProject.id);
                document.getElementsByClassName(
                  "scroll-container"
                )[0].scrollTop = 0;
              }}
            />
            <p
              className="company"
              dangerouslySetInnerHTML={{
                __html: nextProject.subtitle
              }}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default WorkDetail;
