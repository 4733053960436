import React from "react";
import { observer } from "mobx-react";
import windowSize from "../windowSize";

@observer
class AboutBackgroundShape extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  render() {
    const windowWidth = windowSize.width,
      windowHeight = windowSize.height;
    const lineColor = "#2e2e2e";

    const maxInnerWidth = 1350 * 0.75;
    let innerWidth = windowWidth * 0.75;
    innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;

    const boxSize = innerWidth / 4 < 250 ? 300 : innerWidth / 4;
    const xStart = windowWidth / 2 - boxSize / 2;
    const yStart = windowHeight / 2 - boxSize / 2;
    const xCenter = windowWidth / 2;

    const pt1 = { x: xStart, y: yStart + boxSize };
    const pt2 = { x: xStart + pt1.y * 2, y: 0 };
    const pt3 = { x: pt1.x + boxSize / 2, y: pt1.y - boxSize };
    const pt4 = { x: windowWidth, y: pt3.y + xCenter * 2 };
    const points = `${pt2.x},${pt2.y}  ${pt1.x},${pt1.y} ${pt3.x},${pt3.y} ${
      pt4.x
    },${pt4.y}`;
    return (
      <svg width="100%" height="100%" className="background-shape">
        <polyline
          points={points}
          style={{
            fill: "none",
            stroke: lineColor,
            strokeWidth: 1,
            strokeOpacity: 1
          }}
        />
      </svg>
    );
  }
}

export default AboutBackgroundShape;
