import {action, observable} from "mobx";

class WindowSize {
    @observable width = 0;
    @observable height = 0;

    constructor(width, height) {
        this.width = width;
        this.height = height;
    }

    @action setSize = (width, height) => {
        this.width = width;
        this.height = height;
    };
}

const instance = new WindowSize(window.innerWidth, window.innerHeight);

window.addEventListener('resize', () => {
    instance.setSize(window.innerWidth, window.innerHeight);
});

export default instance;