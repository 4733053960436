import React from "react";
import "babel-polyfill";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import About from "./containers/About";
import Works from "./containers/Works";
import WorkDetail from "./containers/WorkDetail";
import AppBar from "./components/AppBar";
import "./App.scss";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);

    const isWorks = window.location.pathname === "/";
    setTimeout(
      () => {
        document.body.classList.add("loaded");
      },
      isWorks ? 2000 : 0
    );
  }

  render() {
    return (
      <Router>
        <div id="app">
          <AppBar color="black" history={this.props.history} />
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/" exact component={Works} />
            <Route path="/works/:id" component={WorkDetail} />
            <Redirect from="/works" exact to="/" />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default AppRouter;
