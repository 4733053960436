import React from "react";
import Visual from "./components/Visual";
import Swiper from "swiper";

class Work18 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = "#" + el.getAttribute("id");
        if (swiperId === "#appbar-swiper") {
          return;
        }
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };

  render() {
    return (
      <div className="page">
        {/* <img className="prototype" src="/prototype/w18.png" /> */}
        <div className="sec">
          <Visual id="18" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                PUMA Basket <br />
                Made by <br />
                BTS & YOU
              </h3>
              <ul>
                <li>#Campaign Planning </li>
                <li>#Brand Identity Design </li>
                <li>#UX/UI Design </li>
                <li>#Interactive Contents Development </li>
                <li>#Media Installation</li>
              </ul>
            </div>
            <div>
              <h4>
                PUMA X BTS 캠페인 디자인 및 <br />
                오프라인 프로모션 구축
              </h4>
              <p>
                푸마는 2015년부터 4년째 파트너십을 유지하고 있는 글로벌 앰버서더
                방탄소년단과 콜라보레이션 신제품 ‘푸마 바스켓(BTS 바스켓)’을
                출시하였습니다. 고객의 사랑과 응원에 보답하고자 PUMA X BTS와
                고객이 함께 특별한 경험을 만들 수 있는 방법이 무엇인지 고민하게
                되었습니다. PUMA BASKET 신제품을 구입하는 과정이 BTS와 더
                가까워지는 특별한 시간으로 기억될 수 있도록 BTS와 콜라보레이션을
                경험할 수 있는 PUMA BASKET MADE BY BTS 온·오프라인 캠페인을
                진행하였습니다. BTS 펜들이 직접 참여하고 공감할 수 있도록
                오프라인 푸마 매장에 푸마 바스켓 구매자를 위한 이벤트 포토부스를
                설치하여 BTS와 자신의 모습이 담긴 단 하나뿐인 포스터를 만들고
                즉석에서 출력하여 소장할 수 있는 이벤트를 마련하였습니다. 푸마
                온라인 사이트에서는 특별 팬 사인회 응모와 자신의 사진과 문구를
                입력해 디지털 화보를 만들고 SNS에 공유하는 포토믹스 서비스를
                제공하였습니다.
              </p>
              <p className="font-semibold">2018. 09</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/348732555"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
        </div>
        <img className="m-img" src="/image/works/18/img01.jpg" />
        {/* <div className="sec" style={{ backgroundColor: "#fc7634" }}>
          <img className="m-img" src="/image/works/18/img01.jpg" />
          <div className="video-container mb-5" />
        </div> */}
        <div className="sec py-10">
          <div className="sec-title-wrap">
            <h3>푸마 매장 포토부스 이벤트</h3>
            <p>
              나를 위한, 나에 의한, 나만의 PUMA X BTS BASKET 포토부스 이벤트
              <br />
              이벤트 매장 - 푸마코리아 압구정 로데오 직영점, 푸마 잠실
              롯데월드몰, 푸마 영등포 타임스퀘어
            </p>
          </div>
          <img className="m-img mt-5" src="/image/works/18/img02.jpg" />
          <img className="m-img mt-5" src="/image/works/18/img03.jpg" />
          <img className="m-img mt-5" src="/image/works/18/img04.jpg" />
        </div>
        <div className="sec swiper-sec py-10">
          <div className="swiper-container detail-swiper" id="swiper1">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/18/slide/1_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/18/slide/1_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/18/slide/1_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/18/slide/1_04.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/18/slide/1_05.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
        </div>
        <img className="bg" src="/image/works/18/bg02.jpg" />
        <div className="sec py-5">
          <img className="m-img" src="/image/works/18/img05.gif" />
        </div>
        <div className="sec" style={{ backgroundColor: "#f2f2f2" }}>
          <div className="sec-title-wrap mt-10">
            <h3>푸마 온라인샵 포토믹스 이벤트</h3>
            <p>
              온라인 캠페인으로 푸마 온라인샵에서는 특별 팬 사인회 응모 및
              포토믹스 서비스를 제공하였습니다. 포토믹스는 푸마와 방탄소년단
              화보에 고객 사진과 문구를 입력해 특별한 화보를 만들고 공유할 수
              있는 서비스로 푸마 응모 사이트에 방문하여 팬 사인회 응모 후 이용할
              수 있는 이벤트로 진행되었습니다.
            </p>
          </div>
          <img className="l-img" src="/image/works/18/img06.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Choi Dong Hoon, Jung Su Han, Kim Do Hyung, You Tae Jin, Han Jung
              Yoon
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work18;
