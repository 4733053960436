import React from "react";
import Visual from "./components/Visual";

class Work17 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page">
        {/* <img className="prototype" src="/prototype/w20.png" /> */}
        <div className="sec">
          <Visual id="17" />
        </div>
        <div className="sec main-sec pt-10">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                FISH ISLAND
                <br />
                tvcf
              </h3>
              <ul>
                <li>#TVCF </li>
                <li>#Mobile Game </li>
                <li>#Production </li>
                <li>#Film</li>
              </ul>
            </div>
            <div>
              <h4>
                Mobile Game ‘Fish Island : 정령의 항로’ <br />
                TVCF 제작
              </h4>
              <p>
                Fish Island ‘정령의 항로’는 NHN Pixelcube에서 출시된 Fish
                island의 2번째 시리즈입니다. ‘리듬으로 즐기는 원터치
                낚시게임’이라는 타이틀에 걸맞게 지루할 틈이 없는 낚시게임으로
                에이플로우에서 TVCF 광고를 제작하였습니다. 낚시 스포츠의
                리드미컬과 생동감과 잘 어울리는 뮤지션인 마이크로닷을 광고
                모델로 기용하고, 실제 바다 위에서 촬영하여 박진감을 살렸습니다.
                매니아층이 두터운 피쉬아일랜드의 광고영상이 온에어 되자,
                후속작을 기다렸던 팬들의 뜨거운 관심으로 구글 플레이 스토어 인기
                게임 순위 TOP 10위를 기록하는 기염을 토했습니다.
              </p>
              <p className="font-semibold">2018. 07</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/346095872"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
          <div className="sec-title-wrap mt-10">
            <h3 className="alogo">FILM DETAILS</h3>
            <p>
              게임 속 체험이 ‘진짜’ 바다처럼 느껴지는 감동의 전달하기 위해 실제
              바다를 배경으로 콘티를 제작하고, 바다 낚시의 역동적이고 경쾌한
              현장감을 영상으로 표현하기 위해 노력했습니다.
            </p>
          </div>
          <img className="m-img mt-5" src="/image/works/17/img01.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img02.jpg" />
          <img className="m-img mt-10" src="/image/works/17/img03.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img04.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img05.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img06.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img13.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img08.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img07.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img09.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img11.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img10.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img12.jpg" />
          <img className="m-img mt-5" src="/image/works/17/img14.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Lim Cho Min
              <br />
              Agency : MAKE U LIKE
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work17;
