import React from "react";
import Visual from "./components/Visual";

class Work08 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page black">
        {/* <img className="prototype" src="/prototype/w09.png" /> */}
        <div className="sec sec1">
          <Visual id="09" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                MU origin <br />
                Launching Video
              </h3>
              <ul>
                <li>#Event Movie</li>
              </ul>
            </div>
            <div>
              <h4>뮤 오리진 런칭행사 영상 제작</h4>
              <p>
                WEBZEN의 인기 게임 ‘뮤 오리진＇의 출시 2주년을 기념하여 제작된
                오프닝 필름입니다. 역동적이며 화려한 그래픽을 자랑하는 뮤
                오리진을 표현하기 위해 실제 댄서의 춤동작을 기본으로 연출하고
                섬세하고 화려한 그래픽을 혼합하여 뮤 오리진의 특색을 담은 런칭
                영상을 제작하였습니다.
              </p>
              <p className="font-semibold">2017. 07</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/346092239"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            />
          </div>
          <div className="sec-title-wrap mt-12">
            <h3>FILM DETAILS</h3>
            <p>
              뮤 오리진의 고객들이 그동안 보여준 사랑들이 점으로 시작하여 선이
              되고 면이 되며 결국엔 뮤 오리진(댄서) 스스로가 아닌 함께 빛이 나게
              되었음을 암시할 수 있도록 제작하였습니다.
            </p>
            <img className="mt-10" src="/image/works/09/img01.jpg" />
            <img className="mt-5" src="/image/works/09/img03.jpg" />
            <img className="mt-5" src="/image/works/09/img04.jpg" />
            <img className="mt-5" src="/image/works/09/img06.jpg" />
            <img className="mt-5" src="/image/works/09/img09.jpg" />
            <img className="mt-5" src="/image/works/09/img10.jpg" />
            <img className="mt-5" src="/image/works/09/img11.jpg" />
            <img className="mt-5" src="/image/works/09/img15.jpg" />
            <img className="mt-5" src="/image/works/09/img21.jpg" />
            <img className="mt-5" src="/image/works/09/img22.jpg" />
            <img className="mt-5" src="/image/works/09/img26.jpg" />
          </div>
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Lim Cho Min
              <br />
              Agency : MAKE U LIKE
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work08;
