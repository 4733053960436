import React from "react";

class Visual extends React.Component {
  state = {
    isDesktop: true
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  onResize = () => {
    const isDesktop = document.body.offsetWidth > 578;
    if (this.state.isDesktop !== isDesktop) {
      this.setState({ isDesktop: isDesktop });
    }
  };

  render() {
    const suffix = this.state.isDesktop ? "" : "_m";
    const imagePath = `/image/works/${this.props.id}/bg01${suffix}.jpg`;
    if (this.state.isDesktop) {
      return (
        <div
          className="main-bg"
          style={{
            backgroundSize: "cover",
            background: `url("${imagePath}") center no-repeat`
          }}
        />
      );
    } else {
      return <img className="main-bg" src={imagePath} alt="main visual" />;
    }
  }
}

export default Visual;
