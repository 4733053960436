import React from "react";
import Visual from "./components/Visual";

class Work12 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page">
        {/* <img className="prototype" src="/prototype/w12.png" /> */}
        <div className="sec">
          <Visual id="12" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                KFA
                <br />
                Official Website
                <br />
                Renewal
              </h3>
              <ul>
                <li>#Website Renewal </li>
                <li>#UX/UI Design </li>
                <li>#Maintenance</li>
              </ul>
            </div>
            <div>
              <h4>
                KFA Official Web-Site Renewal
                <br />
                대한축구협회 웹사이트 리뉴얼 구축 및 연간 운영
              </h4>
              <p>
                대한축구협회는 한국 축구행정 및 회원 단체를 총괄하는 국가대표
                기관으로서 대회일정 및 아카이브 등 종합적인 축구 정보를 제공하는
                웹 사이트를 운영하고 있습니다. 장기 운영으로 분산된 시스템
                구조를 유기적으로 연결하고, 노후한 UX/UI를 개선하여 축구
                팬들에게 더 유익하고 편리한 정보를 제공하기 위해 웹 사이트
                리뉴얼을 진행하였습니다. 사용자의 콘텐츠 소비 성향을 적극 반영한
                UX디자인, 효율적 운영이 가능한 시스템을 설계하였고, 2019년의
                콘텐츠 연간 운영을 이어가고 있습니다.
              </p>
              <p className="font-semibold">2018. 09</p>
            </div>
          </div>
          <img src="/image/works/12/img01.jpg" className="l-img mt-12" />
        </div>
        <div className="sec credit-sec pb-10">
          <div className="sec-title-wrap">
            <h3>STRETAGY</h3>
            <p>
              개편된 대한축구협회 공식 홈페이지는 기존의 협회중심의 콘텐츠에서
              사용자 중심 UX로의 변화를 최우선 과제로 삼았습니다. 사용자의
              콘텐츠 소비패턴을 최우선으로 고려하여, 실시간 피드되는 최신 뉴스의
              시간순 정렬로 첫 화면을 구성하고 분류/검색 기능을 강화하여
              접근성을 향상시켰습니다. 또한, 방대한 양의 각종 기록들의 아카이브
              메뉴를 신설하여, 대한축구협회만이 제공할 수 있는 공신력있고 유용한
              정보를 제공할 수 있도록 구성하였습니다.
            </p>
          </div>
        </div>
        <img className="bg" src="/image/works/12/bg02.jpg" />
        <div
          className="sec dark-bg py-10"
          style={{ backgroundColor: "#003f8b" }}
        >
          <div className="sec-title-wrap">
            <h3 className="mt-12">LIVE CONTENT</h3>
            <p className="mt-5">
              대한축구협회 공식 홈페이지의 첫 화면은 ‘KFA Live’라는 이름으로
              시작합니다. 리그/대회별 메뉴로 진입하여 정보를 확인하던 기존
              방식을 수정하여 최신순으로 정렬된 각 태그별 뉴스 피드로 신속하게
              접근하여 콘텐츠를 소비할 수 있도록 구성하였습니다.
            </p>
            <img src="/image/works/12/img02.png" className="m-img mt-5" />
          </div>
        </div>
        <div className="sec">
          <img src="/image/works/12/img03.jpg" className="m-img mt-5" />
          <img src="/image/works/12/img04.jpg" className="m-img mt-5" />
        </div>
        <div className="sec-title-wrap mt-10">
          <h3 className="mt-10">DATA ARCHIVES</h3>
          <p className="mt-5">
            대한축구협회는 한국 축구 100년사의 다양하고 방대한 공식 기록들을
            보유하고 있습니다. 대회/리그/년도/인물별 각종 기록들을 일목요연하게
            분류하고 가장 효과적인 방법으로 제공합니다.
          </p>
          <div
            className="main-bg main-bg2 mt-5"
            style={{
              background: `url("/image/works/12/bg03.jpg") center no-repeat`,
              backgroundSize: "cover"
            }}
          />
        </div>

        <div>
          <img src="/image/works/12/img05.jpg" className="m-img mt-5" />
          <img src="/image/works/12/img06.jpg" className="m-img mt-5" />
          <img src="/image/works/12/img07.jpg" className="m-img mt-5" />
          <img src="/image/works/12/img08.jpg" className="m-img mt-5" />
          <h4 className="mt-10">Mobile</h4>
          <img src="/image/works/12/img09.png" className="m-img mt-5" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Noh Pil Chul, Jung Su Han, Han Jung Yoon
              <br />
              Planning : Grandslam
              <br />
              Development : Starbucket
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work12;
