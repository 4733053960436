import React from "react";
import Swiper from "swiper";

class Work extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = el.getAttribute("id");
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };
}

export default Work;
