import React from "react";
import windowSize from "../windowSize";
import { observer } from "mobx-react";
import AboutBackgroundShape from "../components/AboutBackgroundShape";
import AboutWhiteBackgroundShape from "../components/AboutWhiteBackgroundShape";
import Footer from "../components/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";

@observer
class About extends React.Component {
  state = {
    patternOffsetY: 0,
    scrollTop: 0,
    missionFadeInClassName: "",
    patternMoveStyle: { opacity: 0.1 },
    patternFixStyle: {},
    backgroundStyle: {}
  };

  constructor(props) {
    super(props);
    this.scrollContainerRef = React.createRef();
    this.aflowSectionRef = React.createRef();
    this.whiteBgRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", e => {
      if (e.keyCode === 40) {
        this.setState({ patternOffsetY: this.state.patternOffsetY + 1 });
      } else if (e.keyCode === 38) {
        this.setState({ patternOffsetY: this.state.patternOffsetY - 1 });
      }
    });
    this.setState({
      scrollTop: this.scrollContainerRef.current.scrollTop,
      missionFadeInClassName: "fadeInUp"
    });
  }

  onScroll = e => {
    const newState = {
      scrollTop: e.target.scrollTop,
      patternOffsetY: e.target.scrollTop / 20
    };

    if (this.state.missionFadeInClassName !== "fadeInUp") {
      newState.missionFadeInClassName =
        this.state.scrollTop > windowSize.height * 0.25 ? "fadeInUp" : "";
    }

    newState.backgroundStyle = this.getBackgroundStyle();
    newState.patternFixStyle = this.getPatternFixStyle();
    newState.patternMoveStyle = this.getPatternMoveStyle();

    this.setState(newState);
  };

  getBackgroundStyle() {
    if (!this.whiteBgRef.current) {
      return { clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)` };
    }

    const height = this.whiteBgRef.current.offsetTop - this.state.scrollTop;
    return {
      clipPath: `polygon(0% 0%, 100% 0%, 100% ${height}px, 0% ${height}px)`
    };
  }

  getPatternFixStyle = () => {
    return {
      width: "3000px",
      opacity: 1,
      top: `calc(50% + ${(39 * windowSize.height) / 800}px)`
    };
  };

  getPatternMoveStyle() {
    const scrollStartOffset = (181 * windowSize.height) / 800;
    const scrollLength = ((581 - 289) * windowSize.height) / 800;
    const scrollPercent = this.aflowSectionRef.current
      ? this.state.scrollTop / this.aflowSectionRef.current.clientHeight
      : 0;

    const yOffset =
      -(
        this.state.patternOffsetY +
        scrollStartOffset -
        scrollLength * scrollPercent
      ) / 10;

    return {
      marginTop: yOffset,
      transform: `translate(-50%, -50%)`,
      width: "3000px",
      opacity: 0.1
    };
  }

  getTitleStyle() {
    const maxInnerWidth = 1350 * 0.75;
    let innerWidth = windowSize.width * 0.75;
    innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;
    const boxSize = innerWidth / 4 < 300 ? 300 : innerWidth / 4;
    const marginTop = windowSize.height / 2 - boxSize / 2;
    return { marginTop: `${marginTop * 0.7}px` };
  }

  getGradientOverlayClassName() {
    if (!this.aflowSectionRef.current) {
      return "";
    }
    return this.aflowSectionRef.current.clientHeight -
      this.state.scrollTop -
      windowSize.height >
      0
      ? "show-slogan"
      : "";
  }

  getBusinessWrap2Style() {
    const maxInnerWidth = 900;
    const windowWidth = windowSize.width;
    let innerWidth;

    if (windowWidth < 600) {
      innerWidth = windowWidth;
    } else {
      innerWidth = windowWidth * 0.75;
      innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;
    }

    const xCenter = windowWidth / 2;
    const xStart = xCenter - innerWidth / 2;
    const boxSize = innerWidth / 2;
    let marginTop = (windowWidth - xStart) / 2 - boxSize;
    let width = 0;
    let height = 0;
    if (windowWidth > 1000) {
      marginTop = marginTop > 200 ? 200 : marginTop;
      height = boxSize;
      width = innerWidth;
    } else {
      marginTop = 160;
      height = 600;
      width = windowSize - 40;
    }

    return {
      marginTop: marginTop + "px",
      height: height + "px",
      width: width + "px"
    };
  }

  render() {
    const clientList = [];
    for (const key in clientImages) {
      const item = clientImages[key];
      clientList.push(
        <li key={key}>
          <img src={`/image/client_logo/${item}.png`} className="col-1" />
        </li>
      );
    }
    return (
      <>
        <div
          className="about-dark-background"
          style={this.state.backgroundStyle}
        >
          <LazyLoadImage
            id="a-pattern-move"
            src="/image/a_pattern_move.png"
            effect="opacity"
            threshold={3000}
            style={this.state.patternMoveStyle}
          />
          <LazyLoadImage
            id="a-pattern-fix"
            src="/image/a_pattern_fixed.png"
            effect="opacity"
            threshold={3000}
            style={this.state.patternFixStyle}
          />
          <AboutBackgroundShape />
        </div>
        <div
          className={`about-container ${this.getGradientOverlayClassName()}`}
          onScroll={this.onScroll}
          ref={this.scrollContainerRef}
        >
          <section className="aflow" ref={this.aflowSectionRef}>
            <div
              className={`inner-width main-title ${this.state.missionFadeInClassName}`}
              style={this.getTitleStyle()}
            >
              <p
                className="alogo"
                dangerouslySetInnerHTML={{ __html: "We make\nAnother flow" }}
              />
              <p className="alogo">We are in a flow</p>
              <p className="alogo">We are A FLOW</p>
            </div>
            <p
              className={`inner-width message pc-only ko ${this.state.missionFadeInClassName}`}
            >
              <br />
              <br />
              변화의 흐름 속에서 새로운 흐름을 만들어 갑니다.
              <br />
              <br />
              에이플로우는 디지털 중심의 커뮤니케이션 미디어와 마케팅 콘텐츠를
              만드는 크리에이티브 디자인 그룹입니다. 현 시대의 커뮤니케이션
              흐름을 관찰하고, 진화하는 디바이스와 기술을 활용하여 브랜드와
              소비자를 이어주는 새로운 관점의 소통 방법을 제시합니다. <br />
              <br />
              우리를 둘러싼 수 많은 기업의 메시지가 우리의 삶과 경험에 유용하게
              쓰일 수 있도록 디지털 미디어라는 그릇에 꼭 필요한 만큼만
              담아내고자 전략적 사고와 균형 잡힌 안목을 키워가고 있습니다.{" "}
              <br />
              <br />
              분야별 전문성에 유연함을 더해 만들어지는 가치,
              <br />
              에이플로우의 새로운 흐름입니다.
              <br />
              <br />
              <br />
            </p>
            <p
              className={`inner-width message pc-only en ${this.state.missionFadeInClassName}`}
            >
              We're creating a new trend in the flow of change.
              <br />
              <br />
              A FLOW is a creative design group that creates digital-centered
              communications media and marketing content. We observe the current
              trends of communication and use evolving devices and technologies
              to create new ways of communicating between brands and consumers.
              <br />
              We are developing strategic thinking and a balanced view to put as
              much as we need in a bowl of digital media so that the messages of
              many companies around us can be useful to our lives and
              experiences.
              <br />
              <br />
              The value created by adding flexibility to discipline expertise,
              <br />
              It's a new flow of A FLOW.
            </p>
            <p
              className={`inner-width message mobile-only ko ${this.state.missionFadeInClassName}`}
            >
              <br />
              <br />
              <span style={{ color: "white", fontSize: "2rem" }}>
                변화의 흐름 속에서
                <br />
                새로운 흐름을 만들어 갑니다.
              </span>
              <br />
              <br />
              에이플로우는 디지털 중심의 커뮤니케이션 미디어와 마케팅 콘텐츠를
              만듭니다.
              <br />
              진화하는 디바이스와 기술을 활용하여 브랜드와 소비자를 이어주는
              새로운 소통 방법을 제시합니다. <br />
              현 시대의 커뮤니케이션 흐름을 관찰하고, <br />
              우리를 둘러싼 수많은 기업의 메시지가 우리의 삶과 경험에 유용하게
              쓰일 수 있도록 디지털 미디어라는 그릇에 꼭 필요한 만큼만 담아
              전달하겠습니다. <br />
              <br />
              2016년 설립 이후 다양한 분야의 프로젝트를 수행하며 유연한
              크리에이티브 전략과 신뢰할 수 있는 완성도를 인정받고, 에이플로우는
              보다 가치 있는 디지털 솔루션을 만들기 위해 도전을 멈추지 않는
              디자인 그룹으로 성장하고 있습니다.
              <br />
              <br />
              <br />
              <br />
            </p>
            <p
              className={`inner-width message mobile-only en ${this.state.missionFadeInClassName}`}
            >
              We're creating a new trend in the flow of change.
              <br />
              <br />
              A FLOW creates digital-centered communications media and marketing
              content. Evolving devices and technologies to create new ways of
              communicating between brands and consumers.
              <br />
              We're looking at the current trends of communication and We’ll
              deliver as much as you need in a bowl of digital media to be
              useful in our lives and experiences that message of so many
              companies around us. <br />
              <br />
              Since its establishment in 2016, We have carried out projects in
              various fields and is recognized for its flexible creative
              strategy and dependable completion. To create more valuable
              digital solutions, We’re growing into a design group that never
              ceases to challenge.
            </p>
          </section>
          <div className="white-bg" ref={this.whiteBgRef}>
            <AboutWhiteBackgroundShape />
            <div className="inner-width">
              <div>
                <div className="business-wrap">
                  <p className="title">Business Area</p>
                  <div>
                    <div>
                      <p className="font-semibold subtitle">
                        Interactive Media Group
                      </p>
                      Interactive Contents Production / 인터랙티브 콘텐츠 제작
                      <br />
                      Service Platform Development / 서비스 플랫폼 개발
                      <br />
                      Spatial Experience Design / 공간 경험 디자인
                    </div>
                    <div>
                      <p className="font-semibold subtitle">
                        Marketing Communication Group
                      </p>
                      SNS Marketing / 소셜 미디어 마케팅
                      <br />
                      Digital Campaign / 디지털 캠페인
                      <br />
                      Film Production / TVCF·바이럴 영상 제작
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="business-wrap2"
                style={this.getBusinessWrap2Style()}
              >
                <ul className="area-title-list font-semibold">
                  <li>
                    Interactive
                    <br />
                    Media
                  </li>
                  <li>
                    Marketing
                    <br />
                    Communication
                  </li>
                  <li>
                    Creative
                    <br />
                    Design
                  </li>
                </ul>
                <ul className="area-list">
                  <li>
                    .<br />
                    Creative Design
                    <br />
                    UX/BX Design
                    <br />
                    Motion Design
                    <br />
                    Space Design
                    <br />
                  </li>
                  <li>
                    .<br />
                    Digital Marketing
                    <br />
                    Social Media Branding
                    <br />
                    Campaign Planning
                    <br />
                    Film Production
                    <br />
                  </li>
                  <li>
                    .<br />
                    Media Producing
                    <br />
                    UX Planning
                    <br />
                    Development
                    <br />
                    Space Media Solution
                    <br />
                  </li>
                </ul>
              </div>
            </div>
            <div className="inner-width client">
              <p className="title">Client</p>
              <ul className="client-list">{clientList}</ul>
            </div>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

const clientImages = [
  "google",
  "sm",
  "naver",
  "jaju",
  "NCsoft",
  "hyundai",
  "samsung",
  "puma",
  "genesis",
  "kfa",
  "PM",
  "upbit",
  "webzen",
  "shinsegae",
  "nhn"
];

export default About;
