import React from "react";
import Work from "./Work";
import Visual from "./components/Visual";
import Swiper from "swiper";

class Work01 extends Work {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = "#" + el.getAttribute("id");
        if (swiperId === "#appbar-swiper") {
          return;
        }
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };

  render() {
    return (
      <div className="page">
        <div className="sec">
          <Visual id="01" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                Parliament
                <br />
                Gallery Alive AR
              </h3>
              <ul>
                <li>#Mobile AR</li>
                <li>#UX/UI Design</li>
                <li>#3D Animation</li>
                <li>#Artwork </li>
                <li>#App Development</li>
              </ul>
            </div>
            <div>
              <h4>
                AR로 감상하는 거장들의 이야기,
                <br />
                Parliament Art Collection AR 어플리케이션 개발
              </h4>
              <p>
                Parliament의 아트 컬렉션 출시에 맞춰 제품패키지를 통해 거장의
                작품을 경험할 수 있는 App을 제작하였습니다. Gallery Alive는
                빈센트 반 고흐, 잭슨 폴록, 피에트 몬드리안 세 거장의 작품을
                생동감 있게 감상하고 각 아티스트의 스타일로 자신만의 작품을 그려
                볼 수 있는 증강현실 모바일 어플리케이션입니다. 카메라 센서를
                활용한 증강현실 기술을 통해서 고흐의 세 가지 작품을 인터랙션이
                가능한 3D 애니메이션으로 감상할 수 있으며 잭슨 폴록의 독특한
                화법을 활용한 페인트볼 터치 게임도 가능합니다. 또한 원하는
                사진을 몬드리안의 작품으로 변환할 수 있는 필터 기능이 포함되어
                있습니다.
              </p>
              <p className="font-semibold">2016. 09</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/346086753"
              allowFullScreen
            />
          </div>
        </div>
        <div
          className="main-bg"
          style={{
            background: `url("/image/works/01/bg02.jpg") center no-repeat`,
            backgroundSize: "cover"
          }}
        />
        <div className="sec sec3" style={{ backgroundColor: "#212225" }}>
          <img className="l-img" src="/image/works/01/bg03.jpg" />
        </div>
        <div
          className="main-bg"
          style={{
            background: `url("/image/works/01/bg04.jpg") center no-repeat`,
            backgroundSize: "cover"
          }}
        />
        <div className="sec pb-10 dark-bg">
          <div className="sec-title-wrap mt-10">
            <h3 className="alogo">VINCENT VAN GOGH</h3>
            <p>
              고흐의 대표작인 ‘별이 빛나는 밤’, ‘고흐의 방’, ‘밤의 카페 테라스’
              속으로 들어가 마치 작품 안에서 실제로 보는 듯한 명화 이야기를
              감상할 수 있습니다.
            </p>
          </div>
          <img className="l-img mt-10" src="/image/works/01/bg05.jpg" />
        </div>
        <img className="device-img" src="/image/works/01/img01.png" />
        <img className="l-img" src="/image/works/01/img02.png" />
        <div>
          <img className="m-img mt-10" src="/image/works/01/img03.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img04.jpg" />
          <img className="m-img mt-5 mb-10" src="/image/works/01/img05.jpg" />
        </div>
        <div className="sec swiper-sec py-10">
          <div
            className="swiper-container detail-swiper m-detail-swiper"
            id="swiper1"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/01/slide/1_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/01/slide/1_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/01/slide/1_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/01/slide/1_04.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/01/slide/1_05.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
        </div>
        <div
          className="main-bg"
          style={{
            background: `url("/image/works/01/bg06.png") center no-repeat`,
            backgroundSize: "cover"
          }}
        />
        <div className="sec dark-bg pb-10">
          <div className="sec-title-wrap mt-10">
            <h3>Jackson Pollock</h3>
            <p>
              날아오는 페인트볼을 튕겨서 맞은편의 캔버스에 자신만의 그림을
              그리는 게임입니다.
              <br />
              게임을 통해 잭슨폴록의 페인팅 기법을 간접적으로 체험할 수
              있습니다.
            </p>
            <img className="l-img mt-10" src="/image/works/01/img13.jpg" />
          </div>
        </div>
        <img className="device-img" src="/image/works/01/img14.png" />
        <div className="sec">
          <img className="l-img" src="/image/works/01/img14.jpg" />
          <img
            className="m-img"
            src="/image/works/01/img15.jpg"
            style={{ marginTop: 0 }}
          />
          <img className="m-img mt-5" src="/image/works/01/img16.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img17.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img18.jpg" />
        </div>
        <div
          className="main-bg mt-10"
          style={{
            background: `url("/image/works/01/bg07.png") center no-repeat`,
            backgroundSize: "cover"
          }}
        />
        <div className="sec pb-10 dark-bg">
          <div className="sec-title-wrap mt-10">
            <h3 className="alogo">PIET MONDRIAN</h3>
            <p>
              다채로운 세상을 몬드리안의 시선으로 바라 볼 수 있는 필터
              카메라입니다.
            </p>
          </div>
          <img className="l-img mt-10" src="/image/works/01/bg08.png" />
        </div>
        <img className="device-img" src="/image/works/01/img19.png" />
        <div className="sec pb-5">
          <img className="m-img" src="/image/works/01/img19.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img20.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img21.jpg" />
          <img className="m-img mt-5" src="/image/works/01/img22.jpg" />
          <img className="m-img my-5" src="/image/works/01/img23.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Choi Dong Hoon, Jung Su Han, Noh Pil Chul, Im Cho Min, You Tae
              Jin, Kim Min Jung
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work01;
