import React from "react";
import Visual from "./components/Visual";

class Work08 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page">
        <div className="sec">
          <Visual id="10" />
        </div>
        <div className="sec main-sec  pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                CJ Hello <br />
                UHD Red <br />
                Branding
              </h3>
              <ul>
                <li>#Service BI Design</li>
                <li>#Application Design</li>
                <li>#Print</li>
              </ul>
            </div>
            <div>
              <h4>
                CJ HELLO UHD Red
                <br />
                서비스 브랜드 아이덴티티 및 어플리케이션 제작{" "}
              </h4>
              <p>
                CJ Hello는 실시간 녹화를 지원하는 신규 서비스 Red를
                출시하였습니다. Recording의 줄임 말인 ‘Red’의 성격을
                타이포그라피적으로 해석하여 Rec(Recording)가 연상될 수 있도록
                BI를 디자인하였고 이를 바탕으로 서비스 리플렛을 포함한 다양한
                홍보물을 제작하였습니다.
              </p>
              <p className="font-semibold">2017. 08</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe src="https://www.youtube.com/embed/2NC-0-hg33o" />
          </div>
          <p className="video-subtitle">
            <span className="bold">출처</span>: CJ Hello. “헬로tv UHD Red”
            <br />
            Online video clip. YouTube, 9 August 2017.
          </p>
          <div className="sec-title-wrap" style={{ marginTop: "12%" }}>
            <h3 className="alogo">BRAND IDENTITY</h3>
            <p>
              CJ Hello UHD Red 서비스명은 Recording / Recording every day의
              줄임말로, 서비스의 특성을 구체적인 설명보다 직관적으로 전달하기
              위한 브랜드 네이밍입니다. 녹화버튼의 붉은 원형 형태를 모티프로
              타이포그라피와 조합하여 Recording의 약어인 ‘Rec’또는 서비스명인
              ‘Red’로도 읽히도록 제작하였습니다.
            </p>
          </div>
        </div>
        <div className="sec">
          <img className="m-img" src="/image/works/10/img01.jpg" />
          <img className="m-img mt-10" src="/image/works/10/img02.jpg" />
          <img className="m-img mt-10" src="/image/works/10/img03.jpg" />
          <div className="sec-title-wrap mt-10">
            <h3 className="alogo">APPLICATIONS</h3>
            <p>
              서비스 브랜드 아이덴티티와 소문자 ‘d’ 형태의 그래픽 패턴을 활용한
              메인 비주얼을 확장하여, 포스터/리플릿/교환광고 및 경품 등 서비스
              홍보와 관련된 다양한 어플리케이션이 제작되었습니다.
            </p>
          </div>
          <h4 className="mt-10">Posters</h4>
          <img className="m-img mt-5" src="/image/works/10/img04.jpg" />
          <img className="m-img mt-5" src="/image/works/10/img05.jpg" />
          <h4 className="mt-10">Leaflets</h4>
          <img className="m-img mt-5" src="/image/works/10/img06.jpg" />
          <img className="m-img mt-5" src="/image/works/10/img07.jpg" />
          <h4 className="mt-10">Gift</h4>
          <img className="m-img mt-5" src="/image/works/10/img08.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>Choi Dong Hoon, Jung Su Han, Noh Pil Chul, Han Jung Yoon</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work08;
