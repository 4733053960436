import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Swiper from "swiper";

class AppBar extends React.Component {
  static propTypes = {
    hide: PropTypes.bool
  };

  static defaultProps = {
    isHidden: false
  };

  state = {
    color: "black",
    isWorks: true,
    showMobileMenu: false,
    bg: null
  };

  componentDidMount = () => {
    const t = this;

    new Swiper("#appbar-swiper", {
      loop: true,
      direction: "vertical",
      autoplay: {
        delay: 5000
      },
      speed: 600,
      coverflowEffect: {
        rotate: 30,
        slideShadows: false
      }
    });

    setInterval(() => {
      t.checkStatus();
    }, 100);
    t.checkStatus();
  };

  checkStatus() {
    const isWorks = window.location.pathname === "/";
    const isWorkDetail = window.location.pathname.search("works/") !== -1;
    let isBlack = isWorks;
    let bg = "";

    if (isWorkDetail) {
      isBlack = true;
      bg = "bg-white";
    } else if (!isWorks) {
      const aboutEls = document.getElementsByClassName("about-container");
      if (aboutEls && aboutEls.length > 0) {
        const aboutEl = aboutEls[0];
        isBlack = aboutEl.scrollTop > aboutEl.children[0].offsetHeight - 50;
      }
    }

    this.setState({
      isWorks: isWorks,
      color: isBlack ? "black" : "white",
      bg: bg
    });
  }

  renderMobileMenu() {
    const showMobileMenu = this.state.showMobileMenu;
    return (
      <div className={`mobile-menu-page ${showMobileMenu ? "show" : "hide"}`}>
        <span
          className="close-btn"
          onClick={() => {
            this.setState({ showMobileMenu: false });
          }}
        >
          close
        </span>
        <div className="menus">
          <Link
            to="/works"
            onClick={() => {
              this.setState({ showMobileMenu: false });
            }}
          >
            Work
          </Link>
          <img src="/image/menu_dash.svg" className="menu-dash" />
          <Link
            to="/about"
            onClick={() => {
              this.setState({ showMobileMenu: false });
            }}
          >
            <span className="alogo">A</span>bout
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={`appbar ${this.state.color} ${this.state.bg} ${
          this.props.isHidden ? "hide" : ""
        }`}
      >
        <Link
          to={"/works"}
          onClick={() => {
            let event = "";
            if (window.CustomEvent) {
              event = new CustomEvent("clickAppbarLogo");
            } else {
              event = document.createEvent("CustomEvent");
              event.initCustomEvent("clickAppbarLogo", true, true);
            }

            window.dispatchEvent(event);
          }}
        >
          <svg className="logo" viewBox="0 0 120 120">
            <polygon points="120 35.5 90.86 50.06 70 0 50 0 0 120 16 120 60 14.4 77.61 56.67 48.28 71.33 39.85 91.57 83.14 69.93 104 120 120 120 96.38 63.31 120 51.5 120 35.5" />
          </svg>
        </Link>
        <div className="menu-left">
          <div className="swiper-container" id="appbar-swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">A FLOW </div>
              <div className="swiper-slide">Another FLOW</div>
              <div className="swiper-slide">Active FLOW</div>
              <div className="swiper-slide">A FLOW</div>
              <div className="swiper-slide">Around FLOW</div>
              <div className="swiper-slide">About FLOW</div>
              <div className="swiper-slide">Attractive Flow</div>
            </div>
          </div>
        </div>

        <svg
          className="menu-mobile"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          onClick={() => {
            this.setState({
              showMobileMenu: true
            });
            //window.location.href = this.state.isWorks ? "/about" : "/works";
          }}
        >
          <path
            id="menu"
            d="M720,45.985L672.007,69.8v6.371L720,52.341V45.985m0,17.84L672.007,87.643v6.372L720,70.182V63.825"
            transform="translate(-672 -46)"
          />
        </svg>
        <div className="menu-right">
          <div className="link">
            <Link
              className={this.state.isWorks ? "active" : ""}
              to="/works"
              onClick={() => {
                this.setState({ isWorks: true });
              }}
            >
              Work
            </Link>
          </div>
          <div className="link">
            <Link
              className={!this.state.isWorks ? "active" : ""}
              to="/about"
              onClick={() => {
                this.setState({ isWorks: false });
              }}
            >
              About
            </Link>
          </div>
        </div>
        {this.renderMobileMenu()}
      </div>
    );
  }
}

export default AppBar;
