import React from "react";
import { withRouter } from "react-router-dom";
import Swiper from "swiper";
import axios from "axios";
import constants from "../constants";
import windowSize from "../windowSize";

class WorksMobile extends React.Component {
  state = {
    damping: 0.1,
    page: "A",
    totalPage: 0,
    projects: [],
    linePoints: "0,0",
    isLastSlide: false
  };

  constructor(props) {
    super(props);

    this.swiper = null;
  }

  renderSlides() {
    const projects = this.state.projects;
    const works = [];
    for (const key in projects) {
      const item = projects[key];
      const imgName = item.id >= 10 ? item.id : "0" + item.id;
      const onClick = e => {
        console.log(e);
        //this.props.history.push("/works/" + item.id);
      };
      const isUpdated = item.is_updated;

      works.push(
        <div className="swiper-slide" key={item.id} onClick={onClick}>
          <div className="img-wrap" style={{ backgroundColor: item.img_color }}>
            <img
              data-src={`/image/works/thumb/mobile/${imgName}.jpg`}
              className="swiper-lazy"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="swiper-wrapper">
        <div className="swiper-slide" />
        {works}
      </div>
    );
  }

  renderTitleSwiper() {
    const projects = this.state.projects;
    const works = [
      <div className="swiper-slide" key={0}>
        <h3>
          A FLOW
          <br />
          is another flow
          <br />
          in current
          <br />
          new media
          <br />
          services
        </h3>
      </div>
    ];
    for (const key in projects) {
      const item = projects[key];
      const onClick = e => {
        if (item.is_updated) {
          this.props.history.push("/works/" + item.id);
        }
      };

      works.push(
        <div className="swiper-slide" key={item.id} onClick={onClick}>
          <h3
            className="alogo"
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
          <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
        </div>
      );
    }

    return (
      <div id="title-swiper" className="swiper-container">
        <div className="swiper-wrapper">{works}</div>
      </div>
    );
  }

  renderOverlay = () => {
    const isFirstPage = this.state.page == "A";

    let isUpdated = true;
    if (this.swiper && this.state.projects && this.swiper.activeIndex > 0) {
      isUpdated = this.state.projects[this.swiper.activeIndex - 1].is_updated;
    }
    console.log("isUpdated: " + isUpdated);

    return [
      <div id="w-overlay-top1" key={0} />,
      <div id="w-overlay-top2" key={1} />,
      <div id="w-overlay-bot" key={2} />,
      <div
        className="scroll-down"
        key={3}
        style={{ opacity: isFirstPage ? 1 : 0 }}
      >
        SCROLL
      </div>,
      <div
        className="not-updated"
        key={4}
        style={{ opacity: isUpdated ? 0 : 1 }}
      >
        TO BE UPDATED
      </div>
    ];
  };

  render() {
    return (
      <div>
        <div className="page" id="swiper-slide-page">
          <div
            className="swiper-container"
            id="work-swiper"
            style={{ height: windowSize.height - 175 }}
          >
            {this.renderSlides()}
            {this.renderOverlay()}
            <svg
              width="100%"
              height="100%"
              preserveAspectRatio="none"
              className="w-overlay-line"
            >
              <polyline
                points={this.state.linePoints}
                style={{
                  fill: "none",
                  stroke: "rgba(0, 0, 0)",
                  strokeWidth: 1,
                  strokeOpacity: 0.1
                }}
              />
            </svg>
          </div>
          <div className="pagination">
            <span>{this.state.page}</span>
            <svg width="100%" height="100%">
              <line
                x1="0"
                y1="20px"
                x2="100%"
                y2="0"
                strokeWidth="3.5"
                stroke="rgb(0, 0, 0)"
              />
            </svg>
            <span>{this.state.totalPage}</span>
          </div>
        </div>
        {this.renderTitleSwiper()}
      </div>
    );
  }

  onResize = () => {
    const windowWidth = document.body.offsetWidth;
    const windowHeight = document.body.offsetHeight;

    const botXEnd = windowWidth * 0.74;
    const botYStart = windowHeight - windowWidth * 0.75 * 2;
    let linePoints = `${windowWidth},185 0,${185 +
      windowWidth / 2} 0,${botYStart} ${botXEnd},${windowHeight}`;

    if (this.swiper) {
      this.swiper.update();
    }
    this.setState({ linePoints: linePoints });
  };

  onClickAppbarLogo = () => {
    this.swiper.slideTo(0);
  };

  componentDidMount = async () => {
    const workResponse = await axios.get(constants.dataBaseUrl + "/works.json");
    this.setState({
      projects: workResponse.data
    });

    if (this.swiper === null) {
      const workSwiper = new Swiper("#work-swiper", {
        direction: "vertical",
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2
        },
        mousewheel: {
          sensitivity: 1,
          releaseOnEdges: true
        }
      });
      this.swiper = workSwiper;

      const titleSwiper = new Swiper("#title-swiper", {
        direction: "vertical"
      });
      workSwiper.controller.control = titleSwiper;
      titleSwiper.controller.control = workSwiper;
    }
    const t = this;
    const swiper = this.swiper;

    const projects = this.state.projects;
    swiper.on("slideChange", () => {
      const isLastSlide = projects.length - swiper.activeIndex === -1;

      console.log(swiper.activeIndex);

      t.setState({
        page:
          swiper.activeIndex === 0
            ? "A"
            : projects.length - swiper.activeIndex + 1,
        totalPage: projects.length,
        isLastSlide: isLastSlide
      });
    });

    const history = this.props.history;
    swiper.on("click", function(e) {
      if (swiper.activeIndex === 0 || projects.length <= swiper.activeIndex) {
        return;
      }
      const item = projects[swiper.activeIndex - 1];
      if (!item.is_updated) {
        return;
      }

      const workId = item.id;
      history.push("/works/" + workId);
    });

    this.setState({
      page: "A",
      totalPage: projects.length - 1
    });

    setTimeout(() => {
      this.onResize();
    }, 1000);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("clickAppbarLogo", this.onClickAppbarLogo);
    this.onResize();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("clickAppbarLogo", this.onClickAppbarLogo);
  };
}

export default withRouter(WorksMobile);
