import React from "react";
import Visual from "./components/Visual";

class Work08 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <div className="page">
        {/* <img className="prototype" src="/prototype/w08.png" /> */}
        <div className="sec sec1">
          <Visual id="08" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3 className="alogo">
                SMTOWN
                <br />
                @Coexartium <br />
                Interactive Media
              </h3>
              <ul>
                <li>#UX/UI Design </li>
                <li>#Art Direction </li>
                <li>#Contents Design </li>
                <li>#Interactive Contents Development </li>
                <li>#Installation</li>
              </ul>
            </div>
            <div>
              <h4>코엑스아티움 스타매칭 인터랙티브 미디어 제작</h4>
              <p>
                SMTOWN@Coexartium 홀로그램 공연을 관람하는 고객이 머무는
                공간에서 대기 시간의 지루함을 덜면서 특별한 경험을 제공하고자
                아티스트와 팬의 궁합을 알아보는 미디어 콘텐츠를 제작하였습니다.
                여러 나라의 다양한 방문객을 고려하여 손금과 별자리 매칭 두 가지
                방식으로 스타와의 궁합 정보를 제공하고 있습니다. 스캔한 손금으로
                알고리즘을 통해 가장 궁합이 맞는 스타를 추천하고 자신의 생일과
                스타의 생일을 분석하여 연애 타입 정보를 제공합니다. 매칭 결과는
                포토 카드 기념품으로 받을 수 있습니다.
              </p>
              <p className="font-semibold">2017. 07</p>
            </div>
          </div>
          <div className="video-container mt-10">
            <iframe
              src="https://player.vimeo.com/video/346092972"
              frameborder="0"
              allowfullscreen
            />
          </div>
          <div className="sec-title-wrap" style={{ marginTop: "13%" }}>
            <h3 className="alogo">ARTIST - USER MATCHING</h3>
            <p>
              SMTOWN@Coexartium - SM 아티스트와 팬의 궁합을 볼 수 있는 미디어로
              스캔한 손금과 생년월일을 분석하여 애정도 결과를 알려주고
              프린트하여 기념할 수 있도록 제작되었습니다.
            </p>
          </div>
        </div>
        <div className="sec" style={{ backgroundColor: "#efb5b5" }}>
          <img className="l-img" src="/image/works/08/img01@2x.jpg" />
        </div>
        <div className="sec py-10">
          <img className="m-img" src="/image/works/08/img02.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img03.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img04.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img05.jpg" />
        </div>
        <div className="sec" style={{ backgroundColor: "#9789e6" }}>
          <img className="bg" src="/image/works/08/img06.jpg" />
        </div>
        <div className="sec">
          <img className="m-img mt-10" src="/image/works/08/img07.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img09.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img08.jpg" />
          <img className="m-img mt-5" src="/image/works/08/img10.jpg" />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>Noh Pil Chul, Jung Su Han, Kim Do Hyung</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work08;
