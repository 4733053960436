import React from "react";
import Swiper from "swiper";
import Visual from "./components/Visual";

class Work21 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = "#" + el.getAttribute("id");
        if (swiperId === "#appbar-swiper") {
          return;
        }
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };

  render() {
    return (
      <div className="page">
        <div className="sec">
          <Visual id="21" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div>
              <h3>
                THE CJ CUP
                <br />@ NINE BRIDGES
                <br />
                Visual Center
              </h3>
              <ul>
                <li>#Brand Identity</li>
                <li>#Integrated Visual System</li>
                <li>#Brand Guidelines</li>
                <li>#Product Design</li>
              </ul>
            </div>
            <div>
              <h4>
                THE CJ CUP @ NINE BRIDGES
                <br />
                비주얼 센터 개발 및 어플리케이션 제작/운영
              </h4>
              <p>
                THE CJ CUP @ NINE BRIDGES는 대한민국 최초의 PGA TOUR로 CJ그룹과
                세계 최고의 골프투어 PGA TOUR, 국제적 골프 코스 나인브릿지가
                만나 2017년 개최를 시작으로 2018년까지 이어졌습니다.
                에이플로우는 THE CJ CUP의 브랜드 아이덴티티를 정의하고, 고유의
                로고타입과 비주얼 모티프, 어플리케이션 시스템을 설계하여 대회의
                진행과 관련된 모든 분야에 일관된 브랜드 아이덴티티를
                적용하였습니다.
              </p>
              <p className="font-semibold">2017. 10 & 2018. 10</p>
            </div>
          </div>
          <img className="m-img mt-12" src="/image/works/21/img01.jpg" />
          <h4 className="mt-10">2017</h4>
          <div className="video-container mt-5">
            <iframe
              src="https://www.youtube.com/embed/nnvj1qBlJt0"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <p className="video-subtitle">
            {/* 2017 THE CJ CUP
            <span className="link">
              https://www.youtube.com/watch?v=nnvj1qBlJt0
            </span> */}
            <span className="bold">출처</span>: THE CJ CUP. “2017 THE CJ CUP @
            NINE BRIDGES 그 뜨거웠던 여정” <br />
            Online video clip. YouTube, 21 May 2018.
          </p>
          <h4 className="mt-10">2018</h4>
          <div className="video-container mt-5">
            <iframe
              src="https://www.youtube.com/embed/u9QzLba6eCA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <p className="video-subtitle">
            <span className="bold">출처</span>: THE CJ CUP. “2018 THE CJ CUP :
            #tournament clip”
            <br />
            Online video clip. YouTube, 22 October 2018.
          </p>
        </div>
        <div className="sec mb-10">
          <div className="sec-title-wrap mt-12">
            <h3 className="alogo">TOURNAMENT LOGO</h3>
            <p>
              THE CJ CUP @ NINE BRIDGES는 글로벌 생활문화기업 CJ와 세계 최고의
              골프투어 PGA TOUR, 국제적 골프 코스 나인브릿지가 만나 개최하는
              대한민국 최초의 PGA TOUR입니다. 강력한 기업 브랜딩을 목표로 그룹
              CI를 중심으로, 골프와 나인브릿지를 상징하는 요소들을 조화롭게
              구성한 브랜드 아이덴티티를 개발하였습니다. 워드마크의 속 공간을
              활용하고 활자간의 연결성을 주어 대회 고유의 정체성을 확립하고자
              하였습니다.
            </p>
          </div>
          <img className="m-img mt-10" src="/image/works/21/gif01.jpg" />
        </div>
        <div className="sec dark-bg pt-10" style={{ backgroundColor: "#111" }}>
          <div className="sec-title-wrap">
            <h3 className="alogo">VISUAL MOTIF</h3>
            <p>
              THE CJ CUP의 비주얼 모티프는 Blossom Symbol과 클럽 나인브릿지의
              아이덴티티가 지닌 조형미에서 착안하여 다양한 크기와 위치, 변화를
              통해 역동적인 움직임을 표현. 교차하며 상승하는 라인들은 화합과
              가능성의 상징이자 모두의 목표, 기회, 꿈을 실현시키는 ‘Bridge to
              Realization’을 의미하도록 개발되었습니다.
              <br />
              <br />
            </p>
            <img className="m-img mt-5" src="/image/works/21/img02.png" />
          </div>
        </div>
        <div class="sec pb-10">
          <img className="m-img mt-10" src="/image/works/21/img03.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img04.jpg" />
          <img className="m-img" src="/image/works/21/img05.jpg" />
          <img className="m-img" src="/image/works/21/img06.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img07.jpg" />
          <img
            className="m-img"
            src="/image/works/21/img08.jpg"
            style={{ marginTop: "-2px" }}
          />
          <img className="m-img" src="/image/works/21/img09.jpg" />
        </div>

        <div className="sec pb-10">
          <div
            className="main-bg"
            style={{
              background: `url("/image/works/21/bg02.jpg") center no-repeat`,
              backgroundSize: "cover"
            }}
          />
          <div className="sec-title-wrap mt-12">
            <h3>PRINTS</h3>
            <p>
              브랜드 아이덴티티와 그래픽 모티프를 확장하여 대회 진행과 관련된
              다양한 인쇄물 디자인이 제작되었습니다. 티켓, 각종 브로셔, 공식
              프로그램북, 시설 및 경기 안내책자, 주차패스, 스코어카드 등이
              해당됩니다.
            </p>
          </div>
          <div
            className="main-bg mt-10"
            style={{
              background: `url("/image/works/21/bg03.jpg") center no-repeat`,
              backgroundSize: "cover"
            }}
          />
          <img className="m-img mt-10" src="/image/works/21/img10.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img11.jpg" />
          <div
            className="mt-5 swiper-container detail-swiper swiper-600"
            id="swiper1"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_04.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_05.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_06.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/1_07.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
        </div>
        <div className="sec-title-wrap mt-10">
          <h3 className="alogo">SIGNAGES</h3>
          <p>
            대회진행에 필요한 실내/외 각종 사이니지에 대한 시스템 설계를 통해
            각종 인포메이션과 장치물들이 제작되었습니다.
            <br /> 그래픽 모티프와 그룹컬러를 활용하여 갤러리/선수/호스피 시설을
            구분 짓고, 코스내/외부에서 선수/갤러리들의 동선을 안배하였습니다.
            시설별로 구분된 국/영문 조합과 코스맵과 이어지는 아이콘 그래픽을
            활용하여 정보 전달의 효율을 높였습니다.
            <br />
            <br />
          </p>
        </div>
        <div className="sec py-10 mt-5" style={{ backgroundColor: "#f2f2f2" }}>
          <img className="l-img" src="/image/works/21/img13.png" />
        </div>
        <div className="sec">
          <div
            className="mt-10 swiper-container detail-swiper swiper-600"
            id="swiper2"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/21/slide/2_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/2_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/2_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/2_04.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
          <img className="m-img mt-10" src="/image/works/21/img15.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img16.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img17.jpg" />
          <img className="m-img mt-10" src="/image/works/21/img18.jpg" />
        </div>
        <div className="sec">
          <div className="sec-title-wrap mt-12">
            <h3 className="alogo">FACILITIES</h3>
            <p>
              경기장 내/외 각종 시설물/편의시설/광고 등에 대한 각종 디자인
              제작물입니다.
              <br />
              티펜스, 호스피탈리티 스위트, 광고보드, 스코어보드, 대형현수막,
              아치, 대형 사이니지, 티 마커 등이 제작되었습니다.
            </p>
            <div
              className="main-bg mt-10"
              style={{
                background: `url("/image/works/21/bg04.jpg") center no-repeat`,
                backgroundSize: "cover"
              }}
            />
            <img className="m-img mt-10" src="/image/works/21/img19.jpg" />
            <img className="m-img mt-5" src="/image/works/21/slide2_01.jpg" />
            <img className="m-img mt-10" src="/image/works/21/img20.jpg" />
            <div
              className="mt-10 swiper-container detail-swiper swiper-600"
              id="swiper3"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_01.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_02.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_03.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_04.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_05.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_06.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/21/slide/3_07.jpg" />
                </div>
              </div>
              <div className="btn-prev" />
              <div className="btn-next" />
            </div>
          </div>
        </div>
        <div className="sec">
          <div className="sec-title-wrap mt-10">
            <h3 className="alogo">CHAMPIONS'S TROPHY DESIGN</h3>
            <p>
              THE CJ CUP의 챔피온 트로피는 한국 고유의 문화유산인 ‘한글’과 세계
              최초의 금속활자인 ‘직지’를 모티브로 하였으며, 모든 참가 선수들의
              이름을 한글 활자로 담고, 우승한 선수의 이름은 파이널 라운드
              현장에서 골드 처리하여 돋보이게 하였습니다. 트로피 하단부의 목재
              다리는 클럽 나인브릿지의 18번 홀에 실재하는 다리를 상징하며,
              대회에 참여한 78명 선수 모두가 그 다리를 지나간다는 의미를
              전달함으로써 대회의 컨셉인 ‘Bridges to realization’을 널리
              알리고자 하였습니다.
            </p>
          </div>
          <div
            className="main-bg mt-10"
            style={{
              background: `url("/image/works/21/bg05.jpg") center no-repeat`,
              backgroundSize: "cover"
            }}
          />
        </div>
        <div className="sec">
          <div className="video-container mt-10">
            <iframe
              src="https://www.youtube.com/embed/5q17KE9sGVc"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <p className="video-subtitle">
            <span className="bold">출처</span>: THE CJ CUP. “한글 이름 담은
            우승트로피 공개”
            <br />
            Online video clip. YouTube, 9 October 2017.
          </p>
          <img className="m-img mt-10" src="/image/works/21/img22.jpg" />
          <div
            className="mt-5 swiper-container detail-swiper swiper-600"
            id="swiper4"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <img src="/image/works/21/slide/4_01.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/4_02.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/4_03.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/4_04.jpg" />
              </div>
              <div className="swiper-slide">
                <img src="/image/works/21/slide/4_05.jpg" />
              </div>
            </div>
            <div className="btn-prev" />
            <div className="btn-next" />
          </div>
          <div
            className="main-bg mt-10"
            style={{
              background: `url("/image/works/21/bg06.jpg") center no-repeat`,
              backgroundSize: "cover"
            }}
          />
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>
              Choi Dong Hoon, Noh Pil Chul, Jung Su Han, Lim Cho Min, Kim Eun
              Kyung
              <br />
              Product Design : Seed Design
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work21;
