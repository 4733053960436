import React from "react";
import { observer } from "mobx-react";
import windowSize from "../windowSize";

@observer
class Footer extends React.Component {
  state = {
    linePoints: "0,0"
  };

  getBgPoints() {
    document.getElementById("footer");
    const footerEl = document.getElementById("footer");
    if (!footerEl) {
      return "0,0";
    }

    const height = footerEl.offsetHeight;
    const points = `${windowSize.width},0, ${
      windowSize.width
    },${height} ${windowSize.width - height * 2},${height}`;

    return points;
  }

  getFooterStyle() {
    const windowWidth = windowSize.width;
    if (windowWidth < 578) {
      return { marginToP: "-45px" };
    }
    const maxInnerWidth = 1350 * 0.75;
    let innerWidth = windowWidth * 0.75;
    innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;

    let marginTop = 0;
    const xDiff = windowWidth - 1600;
    if (xDiff > 0) {
      marginTop = -xDiff / 4;
    }

    const yPad = 200;

    const height = 300 + yPad * 2 + (windowWidth - innerWidth) / 4;
    return {
      height: height + "px",
      marginTop: marginTop + "px"
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
    setTimeout(() => {
      this.onResize();
    }, 200);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  onResize = () => {
    const linePoints = this.getBgPoints();

    this.setState({ linePoints: linePoints });
  };

  render() {
    return (
      <div id="footer" className="footer" style={this.getFooterStyle()}>
        <svg width="100%" height="100%" preserveAspectRatio="none">
          <polygon
            points={this.state.linePoints}
            style={{
              fill: "black"
            }}
          />
        </svg>
        <div className="footer-container">
          <div>
            <img className="logo" src="/image/logo_A_white.svg" />
            <p className="alogo corp">© 2019. A FLOW</p>
          </div>
          <div className="info">
            <div className="instagram">
              <p className="label">Follow us</p>
              <p>
                <a target="_blank" href="https://instagram.com/aflow_official">
                  instagram
                </a>
              </p>
            </div>
            <div className="phone">
              <p className="label">Phone</p>
              <p>
                <a href="callto:+8207051215726">+82.070.5121.5726</a>
              </p>
            </div>
            <div className="contact">
              <p className="label">Contact</p>
              <p>
                <a href="mailto: aflow@aflow.kr">aflow@aflow.kr</a>
              </p>
            </div>
            <div className="address">
              <p className="label">Address</p>
              <p>
                3F, 25, Dosan-daero 11-gil,
                <br />
                Gangnam-gu, Seoul,
                <br />
                Republic of Korea
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
