import React from "react";
import Swiper from "swiper";
import Work from "./Work";
import Visual from "./components/Visual";

class Work06 extends Work {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const t = this;
    setTimeout(() => {
      document.querySelectorAll(".swiper-container").forEach(el => {
        const swiperId = "#" + el.getAttribute("id");
        if (swiperId === "#appbar-swiper") {
          return;
        }
        new Swiper(swiperId, {
          loop: true,
          navigation: {
            nextEl: swiperId + " .btn-next",
            prevEl: swiperId + " .btn-prev"
          }
        });
      });
    }, 1000);
  };

  render() {
    return (
      <div className="page dark-bg">
        <div className="sec sec1">
          <Visual id="06" />
        </div>
        <div className="sec main-sec pt-10 pb-12">
          <div className="text-wrap">
            <div className="dark-bg">
              <h3 className="alogo">
                NCSOFT <br />
                2017~2018 Yearly <br />
                Maintenance
              </h3>
              <ul>
                <li>#Contents Design</li>
                <li>#Maintenance</li>
              </ul>
            </div>
            <div>
              <h4>
                NCSOFT 리지니M, 리니지 레드나이츠, H2, <br />
                파이널 블레이드 온라인 광고 제작
              </h4>
              <p>
                NCSOFT의 리니지M, 리니지 레드나이츠, H2, 파이널 블레이드 온라인
                광고를 연간 시즌 이슈에 적합하도록 제작/운영했습니다. 모든 매체
                사이즈를 분석 후 각 소재에 적합한 가이드를 개발하여 매체 간
                완성도와 통일성을 유지하였습니다. 각 게임별 브랜드 아이덴티티에
                적합한 디자인 특성과 게임성을 강조했으며, 전환율에 기여할 수
                있도록 가독성을 높이고 눈에 띄는 CTA를 제작했습니다.
              </p>
              <p className="font-semibold">2017 ~ 2018</p>
            </div>
          </div>
        </div>
        <div className="sec">
          <div className="sec-title-wrap">
            <img src="/image/works/06/img01.jpg" />
            <p>
              <br />
              19년 된 PC 온라인 게임 ‘리니지’의 세계관을 이은 ‘리니지M’의 온라인
              광고 디자인은 원작 고유의 게임성을 나타낼 수 있도록 구성했습니다.
              2017년 4월 사전 예약 온라인 광고를 포함하여 각 시즌에 맞는
              콘텐츠를 제작 운영했습니다. 국내 모바일 게임 사상 최다 사전예약
              550만 명을 성공적으로 모집했습니다. 또한 출시 이후 현재까지 구글
              플레이 마켓 매출 순위 1위를 기록하고 있습니다.
            </p>
            <div
              className="swiper-container detail-swiper m-detail-swiper mt-10"
              id="swiper1"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_01.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_02.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_03.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_04.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_05.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_06.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_07.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_08.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_09.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/1_10.jpg" />
                </div>
              </div>
              <div className="btn-prev" />
              <div className="btn-next" />
            </div>
          </div>
          <div className="sec-title-wrap mt-10">
            <img src="/image/works/06/img02.jpg" />
            <p>
              <br />
              ‘리니지 레드나이츠’는 원작 ‘리니지’ 캐릭터와 세계관을 독창적인
              방식으로 재해석한 게임이기 때문에 타이포와 디자인 톤앤매너를
              캐주얼하게 구성했습니다. 기존 리니지 캐릭터와 다르게 SD 형태의
              캐릭터를 기본으로 하는 게임의 특징을 광고 소재로도 적극
              활용했습니다.
            </p>
            <div
              className="swiper-container detail-swiper m-detail-swiper mt-10"
              id="swiper2"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_01.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_02.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_03.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_04.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_05.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/2_06.jpg" />
                </div>
              </div>
            </div>
          </div>
          <div className="sec-title-wrap mt-10">
            <img src="/image/works/06/img03.jpg" />
            <p>
              <br />
              동양 판타지 세계관을 바탕으로 한 ‘파이널 블레이드’의 독창적이면서
              서정적인 화풍의 그래픽을 메인으로 온라인 광고를 제작했습니다.
              2017년 2월 출시 이전 사전예약 광고를 포함하여 개별 시즌 이슈에
              맞게 소재를 개발했습니다. ‘파이널 블레이드’는 출시 15일 만에 누적
              다운로드 100만 다운로드를 달성했으며, 출시 초반 구글 플레이 매출
              3위를 기록하기도 했습니다.
            </p>
            <div
              className="swiper-container detail-swiper m-detail-swiper mt-10"
              id="swiper3"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_01.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_02.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_03.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_04.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_05.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/3_06.jpg" />
                </div>
              </div>
            </div>
          </div>
          <div className="sec-title-wrap mt-10">
            <img src="/image/works/06/img04.jpg" />
            <p>
              <br />
              ‘프로야구 H2’는 유저가 감독으로서 프로야구 구단을 운영하는
              매니지먼트 게임입니다. 온라인 광고에서는 야구의 극적인 장면을
              활용하여 게임의 역동성을 전달하고자 했습니다. 2017년 3월 출시 이전
              사전 예약 광고를 포함한 시즌별 광고를 제작했으며 ‘프로야구 H2’는
              매니지먼트 게임 사상 최단기간 1위 달성 및 100만 다운로드를
              돌파했습니다. 또한 구글 플레이와 애플 앱스토어 양대 마켓에서
              스포츠 게임 매출 1위를 기록하기도 했습니다.
            </p>
            <div
              className="swiper-container detail-swiper m-detail-swiper mt-10"
              id="swiper4"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_01.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_02.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_03.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_04.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_05.jpg" />
                </div>
                <div className="swiper-slide">
                  <img src="/image/works/06/slide/4_06.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec credit-sec py-10">
          <div className="sec-title-wrap">
            <h3>CREDIT</h3>
            <p>Noh Pil Chul, Kim Myung Ha, Nam Eun Bi, You Seung Ji</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Work06;
