import React from "react";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";
import { TimelineLite, CSSPlugin, ScrollToPlugin } from "gsap/all";
import axios from "axios";
import constants from "../constants";
import windowSize from "../windowSize";
import { LazyLoadImage } from "react-lazy-load-image-component";

class WorksDesktop extends React.Component {
  state = {
    isLoading: true,
    page: '<span className="alogo">A</span>',
    totalPage: "21",
    patternStyle: {},
    topBtnClass: "black",
    leftPoints: "0,0",
    topPoints: "0,0",
    botPoints: "0,0",
    bot2Points: "0,0",
    linePoints: "0,0",
    linePoints2: "0,0",
    linePoints3: "0,0",
    projects: [],
    scrollTop: 0
  };

  constructor(props) {
    super(props);

    this.scrollContainerRef = React.createRef();
  }

  renderScrollContainer() {
    const projects = this.state.projects;
    const works = [];
    for (const key in projects) {
      const item = projects[key];
      const imgName = item.id >= 10 ? item.id : "0" + item.id;
      const isUpdated = item.is_updated;
      const onClick = () => {
        if (!isUpdated) {
          return;
        }
        this.props.history.push("/works/" + item.id);
      };

      works.push(
        <div className={`work ${isUpdated ? "updated" : ""}`} key={key}>
          <div>
            <h3
              className="alogo"
              dangerouslySetInnerHTML={{ __html: item.title }}
              onClick={onClick}
            />
            <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
          </div>
          <div
            className="img-wrap"
            style={{ backgroundColor: item.img_color }}
            // onClick={onClick}
          >
            <img src={`/image/works/thumb/${imgName}.jpg`} onClick={onClick} />
            {/* <LazyLoadImage
              alt="work"
              effect="blur"
              threshold={1000}
              src={`/image/works/thumb/${imgName}.jpg`}
            /> */}
          </div>
        </div>
      );
    }

    return (
      <div
        className="scroll-container"
        onScroll={e => {
          this.onScroll(e.target.scrollTop);
        }}
        ref={this.scrollContainerRef}
      >
        <div className="work-wrap">
          <div className="title" id="work-title">
            <h3 className="alogo" style={{ width: "100%" }}>
              <span className="alogo">A</span> FLOW is another flow
              <br />
              in current new media services
            </h3>
          </div>
          {works}
        </div>
        <Footer />
      </div>
    );
  }

  renderBackground = () => {
    return [
      <img
        className="w-pattern"
        id="w-pattern-fix"
        src="/image/w_pattern_fixed.png"
        key={0}
      />,
      <img
        className="w-pattern"
        id="w-pattern-move"
        src="/image/w_pattern_move.png"
        style={this.state.patternStyle}
        key={1}
      />,
      <div className="loading-bg" key={5} />
    ];
  };

  renderBackOverlay = () => {
    return [
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        key={0}
        id="w-back-overlay"
        className="w-overlay"
      >
        <polygon
          points={this.state.leftPoints}
          style={{
            fill: "white"
          }}
        />
        <polygon
          points={this.state.topPoints}
          style={{
            fill: "white"
          }}
        />
        <polygon
          points={this.state.botPoints}
          style={{
            fill: "white"
          }}
        />
      </svg>,
      <svg
        className="w-overlay-line w-overlay"
        width="100%"
        height="100%"
        key={1}
        preserveAspectRatio="none"
      >
        <polyline
          points={this.state.linePoints}
          style={{
            fill: "none",
            stroke: "#dcdcdc",
            strokeWidth: 1,
            strokeOpacity: 1
          }}
        />
      </svg>
    ];
  };

  renderFrontOverlay = () => {
    return [
      <svg id="w-overlay-top2" className="w-overlay" key={0}>
        <polygon
          points={this.state.top2Points}
          style={{
            fill: "rgb(255, 255, 255)"
          }}
        />
      </svg>,
      <svg id="w-overlay-bot2" className="w-overlay" key={1}>
        <polygon
          points={this.state.bot2Points}
          style={{
            fill: "rgba(255, 255, 255, 0.7)"
          }}
        />
      </svg>,
      <div className="w-overlay-line2 w-overlay" key={7}>
        <svg width="100%" height="100%" preserveAspectRatio="none">
          <polyline
            points={this.state.linePoints2}
            style={{
              fill: "none",
              stroke: "#dcdcdc",
              strokeWidth: 1,
              strokeOpacity: 1
            }}
          />
        </svg>
      </div>,
      <div className="w-overlay-line3 w-overlay" key={8}>
        <svg width="100%" height="100%" preserveAspectRatio="none">
          <polyline
            points={this.state.linePoints3}
            style={{
              fill: "none",
              stroke: "#dcdcdc",
              strokeWidth: 1,
              strokeOpacity: 1
            }}
          />
        </svg>
      </div>
    ];
  };

  renderFloat() {
    return [
      <button
        key="btn-top"
        className={`btn-top font-bold ${this.state.topBtnClass}`}
        onClick={() => {
          this.scrollTo(0, 400);
        }}
      >
        top
      </button>,
      <div className="desktop-pagination" key="pagination">
        <span dangerouslySetInnerHTML={{ __html: this.state.page }} />
        <svg width="100%" height="100%">
          <line
            x1="0"
            y1="20px"
            x2="100%"
            y2="0"
            strokeWidth="3.5"
            stroke="rgb(0, 0, 0)"
          />
        </svg>
        <span>{this.state.totalPage}</span>
      </div>
    ];
  }

  render() {
    return (
      <div className="page" id="work-page">
        {this.renderBackground()}
        {this.renderBackOverlay()}
        {this.renderScrollContainer()}
        {this.renderFrontOverlay()}
        {this.renderFloat()}
      </div>
    );
  }

  mergePoints = points => {
    if (!points || points.length === 0) {
      return;
    }

    let ret = "";
    for (const point of points) {
      ret += parseInt(point.x) + "," + parseInt(point.y) + " ";
    }

    return ret;
  };

  onResize = () => {
    const windowWidth = document.body.offsetWidth;
    const windowHeight = document.body.offsetHeight;
    if (windowWidth < 578) {
      return;
    }

    const workTitleEl = document.getElementById("work-title");

    const maxInnerWidth = 1350 * 0.75;

    let innerWidth = windowWidth * 0.75;
    innerWidth = innerWidth > maxInnerWidth ? maxInnerWidth : innerWidth;

    const xCenter = windowWidth / 2;

    const boxTopOffset = -40;
    const boxSize = innerWidth / 4;
    const boxLeft = xCenter - innerWidth / 2 - 20;
    const boxTop = (innerWidth + 20) / 2 - boxSize + boxTopOffset;

    const y1 = (innerWidth + (windowWidth - innerWidth) / 2) / 2 + boxTopOffset;
    const lineFinishX = boxLeft + boxSize / 2 + (windowHeight - boxTop) / 2;

    const pt1 = { x: y1 * 2, y: 0 };
    const pt2 = { x: boxLeft, y: boxTop + boxSize };
    const pt3 = { x: boxLeft + boxSize / 2, y: boxTop };
    const pt4 = { x: lineFinishX, y: windowHeight + 1 };

    // 패턴 고정
    const patternFixEl = document.getElementById("w-pattern-fix");
    if (patternFixEl) {
      patternFixEl.style.top = boxTop + boxSize - 1500 + "px";
    }

    // 패턴 움직임
    const patternMoveEl = document.getElementById("w-pattern-move");
    if (patternMoveEl) {
      patternMoveEl.style.top = boxTop + boxSize - 1500 + "px";
    }

    // 좌측 오버레이
    if (workTitleEl) {
      workTitleEl.style.marginTop = `${boxTop}px`;
      workTitleEl.style.height = `${boxSize}px`;
    }

    const divideX = xCenter - innerWidth * 0.09;

    this.setState({
      leftPoints: this.mergePoints([
        { x: 0, y: 0 },
        { x: pt3.x + 2, y: 0 },
        { x: pt3.x + 2, y: windowHeight },
        { x: 0, y: windowHeight }
      ]),
      topPoints: this.mergePoints([pt1, pt2, { x: pt2.x, y: 0 }]),
      top2Points: this.mergePoints([
        pt1,
        { x: divideX, y: 0 },
        { x: divideX, y: (pt1.x - divideX) / 2 }
      ]),
      botPoints: this.mergePoints([pt3, pt4, { x: pt3.x, y: windowHeight }]),
      bot2Points: this.mergePoints([
        pt4,
        { x: divideX, y: windowHeight - (lineFinishX - divideX) * 2 },
        { x: divideX, y: windowHeight }
      ]),
      linePoints: this.mergePoints([pt1, pt2, pt3, pt4]),
      linePoints2: this.mergePoints([pt1, { x: divideX, y: y1 - divideX / 2 }]),
      linePoints3: this.mergePoints([
        { x: divideX, y: windowHeight - (lineFinishX - divideX) * 2 },
        pt4
      ])
    });
  };

  onClickAppbarLogo() {
    this.scrollTo(0, 400);
  }

  onScroll(scrollTop) {
    // 패턴 이동
    const windowHeight = windowSize.height;
    const windowWidth = windowSize.width;
    const scrollHeight = this.scrollContainerRef.current.scrollHeight;
    const scrollOffset = scrollHeight - windowHeight - scrollTop;
    if (scrollOffset <= 0) {
      return;
    }
    try {
      const patternEl = document.getElementById("w-pattern-move");
      if (patternEl) {
        const offset = 20;

        const yOffset = -80 - scrollTop / parseInt(offset) + "px";
        patternEl.style.transform = `translate3d(-50%, -50%)`;
        patternEl.style.marginTop = yOffset;
      }

      // 페이드인 효과
      const els = document.querySelectorAll(".work");
      let page = 0;
      Array.prototype.forEach.call(els, (el, i) => {
        const isWideInsideScreen =
          el.offsetTop > scrollTop - 400 &&
          el.offsetTop < scrollTop + document.body.offsetHeight + 400;
        if (!isWideInsideScreen) {
          return;
        }

        const isInsideScreen =
          el.offsetTop > scrollTop &&
          el.offsetTop < scrollTop + document.body.offsetHeight;

        if (page === 0) page = i;

        if (
          el.offsetTop > scrollTop &&
          el.offsetTop < scrollTop + document.body.offsetHeight
        ) {
        }
        let imgOffset = -(el.offsetTop - scrollTop - 250) / 25;

        const imgEl = el.querySelector("img");
        if (imgEl) {
          imgEl.style.transform = `translate3d(0,${-50 + imgOffset}%, 0)`;
        }

        if (isInsideScreen) {
          el.classList.add("visible");
        }
      });

      const workCount = els.length;

      if (page == 0 && scrollTop > document.body.offsetHeight) {
        page = workCount - 1;
      }

      const overlayTop2El = document.getElementById("w-overlay-top2");
      if (overlayTop2El) {
        overlayTop2El.style.display = scrollTop < 400 ? "none" : "block";
      }

      const overlayLine2El = document.getElementsByClassName(
        "w-overlay-line2"
      )[0];
      if (overlayLine2El) {
        overlayLine2El.style.opacity = scrollTop < 400 ? 0 : 1;
      }

      const overlayBot2El = document.getElementById("w-overlay-bot2");
      const overlayLine3El = document.getElementsByClassName(
        "w-overlay-line3"
      )[0];
      const footerEl = document.getElementsByClassName("footer")[0];

      if (overlayBot2El && overlayLine3El) {
        const scrollHeight = this.scrollContainerRef.current.scrollHeight;
        const offset =
          scrollHeight -
          scrollTop -
          windowSize.height -
          footerEl.offsetHeight +
          200;
        overlayBot2El.style.display = offset < 0 ? "none" : "block";
        overlayLine3El.style.display = offset < 0 ? "none" : "block";
      }

      const overlayLine = document.getElementsByClassName("w-overlay-line")[0];
      if (overlayLine) {
        // overlayLine.style.zIndex = workCount - page <= 1 ? 8 : 11;
      }

      if (scrollTop === 0) {
        page = "A";
      } else {
        page = page < 0 ? 0 : page;
        page = page >= workCount ? workCount : page;
        page = page === 0 ? "A" : workCount - page;
      }

      // 탑버튼 스타일
      let topBtnClass = scrollTop > 1000 ? "visible" : "";
      const footerHeight = document.getElementById("footer").offsetHeight;
      const isInnerFooter =
        scrollHeight - scrollTop - windowHeight <
        footerHeight - windowWidth / 2 - 50;
      topBtnClass += isInnerFooter ? " white" : " black";

      this.setState({
        page: page,
        topBtnClass: topBtnClass,
        totalPage: workCount
      });
    } catch (e) {
      // Do nothing
    }
  }

  scrollTo = function(to, duration) {
    const element = this.scrollContainerRef.current,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date();
    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };
    animateScroll();
  };

  urlParams = name => {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return decodeURI(results[1]) || 0;
    }
  };

  componentDidMount = async () => {
    const t = this;

    // 목록 데이터 로드
    const workResponse = await axios.get(constants.dataBaseUrl + "/works.json");
    const scrollSensitivity = 0.7;
    this.setState({
      scrollSensitivity: scrollSensitivity,
      projects: workResponse.data
    });

    // 이벤트 바인딩
    window.addEventListener("resize", this.onResize);
    window.addEventListener("clickAppbarLogo", this.onClickAppbarLogo);
    this.onResize();

    const isLoaded = document.body.classList.contains("loaded");
    const tl = new window.TimelineLite();
    if (isLoaded) {
      const overlayLineEl = document.getElementsByClassName(
        "w-overlay-line"
      )[0];
      overlayLineEl.style.opacity = 1;
      tl.from(".appbar", 0, { opacity: 0 })
        .to(".appbar", 0, { opacity: 1 })
        .to(".work-wrap", 0, { opacity: 1 })
        .to(".loading-bg", 0, {
          opacity: 0
        })
        .to(".desktop-pagination", 0, { opacity: 1 });
    } else {
      setTimeout(() => {
        const isMobile = document.body.offsetWidth < 568;
        if (isMobile) {
          return;
        }

        const overlayLineEl = document.getElementsByClassName(
          "w-overlay-line"
        )[0];
        if (overlayLineEl) {
          overlayLineEl.style.opacity = 1;
          overlayLineEl.classList.add("animate");
        }

        tl.delay(3)
          .from(".appbar", 0, {
            opacity: 0
          })
          .to(".appbar", 0, { opacity: 1 })
          .to(".loading-bg", 0.2, {
            opacity: 0,
            delay: 0.2
          })
          .to(".desktop-pagination", 0.6, {
            opacity: 1,
            onComplete: () => {
              // const overlayLineEl = document.getElementsByClassName(
              //   "w-overlay-line"
              // )[0];
              // overlayLineEl.style.zIndex = 8;

              const overlayLine3El = document.getElementsByClassName(
                "w-overlay-line3"
              )[0];
              if (overlayLine3El) {
                overlayLine3El.style.opacity = 1;
              }
              // const overlayBot2El = document.getElementById("w-overlay-bot2");
              // overlayBot2El.style.zIndex = 9;
            }
          })
          .to(".work-wrap", 2, {
            opacity: 1
          });

        t.onScroll(t.scrollContainerRef.current.scrollTop);
        t.onResize();
      }, 1000);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("clickAppbarLogo", this.onClickAppbarLogo);
  };
}

export default withRouter(WorksDesktop);
